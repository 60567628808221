import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import validator from "validator";

import { Box, HStack, Stack } from "@suited/components";
import { isEmptyObject } from "@suited/utils";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedLink from "suited/components/shared/buttons/SuitedLink";
import { ResponsiveDialogModal } from "suited/components/shared/feedback/ResponsiveDialogModal/ResponsiveDialogModal";
import { InputErrorMessage } from "suited/components/shared/inputs/InputErrorMessage/InputErrorMessage";
import { StyledTextInput } from "suited/components/shared/inputs/StyledTextInput/StyledTextInput";
import SuitedBasicConfirmFooter from "suited/components/shared/layout/Dialogs/SuitedBasicConfirmFooter";
import SuitedDialogConfirm from "suited/components/shared/layout/Dialogs/SuitedDialogConfirm";
import { SuitedAltInputLabel } from "suited/components/shared/typography/SuitedAltInputLabel";
import SuitedFormInstructions from "suited/components/shared/typography/SuitedFormInstructions";
import { timeouts } from "suited/constants/interaction-constants";
import { DEFAULT_REQUIRED_ERROR_MESSAGE } from "suited/constants/validation.constants";
import { useAppDispatch } from "suited/util/hooks/reduxHooks";

import { ConfirmSecondaryEmailNotification } from "../userAdditionalInfo/ConfirmSecondaryEmailNotification";
import { ServerError } from "../userAdditionalInfo/UserAdditionalInfoOverlay.styles";
import { INITIATE_CHANGE_EMAIL_SECONDARY } from "./GraphQL/mutations";
import { GET_USER_SETTINGS } from "./GraphQL/queries";
import { SWAP_PRIMARY_AND_SECONDARY_EMAILS } from "./UserSecondaryEmailForm.queries";
import { showAddSecondaryEmailModal } from "./userSettings.slice";

type FormValues = {
  secondaryEmail: string;
};

export const UserSecondaryEmailForm = () => {
  const [showSetPrimaryEmailDialog, setShowSetPrimaryEmailAddressDialog] = useState(false);
  const [serverError, setServerError] = useState("");
  const [isEmailConfirmationModalOpen, setIsEmailConfirmationModalOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { data, refetch } = useQuery(GET_USER_SETTINGS);
  const [swapPrimaryAndSecondaryEmails] = useMutation(SWAP_PRIMARY_AND_SECONDARY_EMAILS, {
    variables: {},
    onCompleted: () => {
      refetch();
      setShowSetPrimaryEmailAddressDialog(false);
    }
  });
  const user = data?.GetUser;

  const closeAndResetView = () => {
    dispatch(showAddSecondaryEmailModal(false));
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty }
  } = useForm({
    mode: "all",
    defaultValues: {
      secondaryEmail: user?.secondaryEmail
    }
  });

  useEffect(() => {
    reset(user);
  }, [reset, user]);

  const [initiateChangeEmail] = useMutation(INITIATE_CHANGE_EMAIL_SECONDARY);

  async function setEmailChangeView(data: FormValues) {
    setIsEmailConfirmationModalOpen(true);
    try {
      await initiateChangeEmail({ variables: { email: data.secondaryEmail } });
    } catch (error: any) {
      setServerError("Error updating email address");
      return;
    }
  }

  const onSubmit = (data: FormValues) => {
    setEmailChangeView(data);
  };

  const formHasErrors = !isEmptyObject(errors);
  const isDisabled = !isDirty || formHasErrors;

  const email = watch("secondaryEmail");

  return (
    <>
      <ResponsiveDialogModal
        maxWidth={700}
        show={isEmailConfirmationModalOpen}
        onCancel={closeAndResetView}
        closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
        iconButtonRow={
          <>
            {/* Need to add placeholder to replace Basic Dialog header space */}
            <div style={{ height: "30px" }}></div>
          </>
        }
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
      >
        <ConfirmSecondaryEmailNotification secondaryEmail={email} />
      </ResponsiveDialogModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack space="sm" data-test="secondary-email-change-form">
          <Box>
            <Stack space="none">
              <SuitedAltInputLabel htmlFor="secondary-email">Secondary/Personal Email*</SuitedAltInputLabel>
              <StyledTextInput
                id="secondary-email"
                {...register("secondaryEmail", {
                  required: DEFAULT_REQUIRED_ERROR_MESSAGE,
                  validate: (email: string) =>
                    validator.isEmail(email) || "Email address is invalid"
                })}
                error={errors?.secondaryEmail?.message}
              />
              <InputErrorMessage message={errors?.secondaryEmail?.message} />
            </Stack>
          </Box>
          <HStack justify="flex-end" align="center">
            <Box>
              <SuitedLink onClick={() => setShowSetPrimaryEmailAddressDialog(true)}>
                make primary email
              </SuitedLink>
            </Box>
            <Box>
              <SuitedButton
                alignRight
                formButton
                type="submit"
                purpose="primary"
                disabled={isDisabled}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              >
                Save
              </SuitedButton>
              {serverError && (
                <ServerError>
                  There was an issue submitting your contact information. Please refresh the page
                  and try again or contact our{" "}
                  <SuitedLink
                    href="mailto:support@wellsuited.com"
                    target="_blank"
                    tooltip="Email Support"
                  >
                    support team
                  </SuitedLink>{" "}
                  for help.
                </ServerError>
              )}
            </Box>
          </HStack>

          <SuitedDialogConfirm
            show={showSetPrimaryEmailDialog}
            title="Update Primary Email Address"
            message={
              <SuitedFormInstructions>
                Are you sure you want to set this as your primary email address? This will become
                your new sign-in email.
              </SuitedFormInstructions>
            }
            onCancel={() => setShowSetPrimaryEmailAddressDialog(false)}
            renderFooter={
              <SuitedBasicConfirmFooter
                cancelText="No"
                confirmText="Yes"
                onCancel={() => setShowSetPrimaryEmailAddressDialog(false)}
                onConfirm={() => swapPrimaryAndSecondaryEmails()}
              />
            }
          />
        </Stack>
      </form>
    </>
  );
};
