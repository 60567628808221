import { ApolloError, useApolloClient, useMutation, useQuery } from "@apollo/client";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import validator from "validator";

import { Box, Flex, HStack, Stack } from "@suited/components";

import SuitedLink from "suited/components/shared/buttons/SuitedLink";
import { timeouts } from "suited/constants/interaction-constants";
import { useAppDispatch } from "suited/util/hooks/reduxHooks";

import { SuitedButton } from "../../shared/buttons/SuitedButton";
import { ResponsiveDialogModal } from "../../shared/feedback/ResponsiveDialogModal/ResponsiveDialogModal";
import { InputErrorMessage } from "../../shared/inputs/InputErrorMessage/InputErrorMessage";
import { StyledTextInput } from "../../shared/inputs/StyledTextInput/StyledTextInput";
import { SuitedAltInputLabel } from "../../shared/typography/SuitedAltInputLabel";
import SuitedCopyCaption from "../../shared/typography/SuitedCopyCaption";
import SuitedCopySubheadline from "../../shared/typography/SuitedCopySubheadline";
import { GET_USER_BOOTSTRAP } from "../GraphQL/Queries";
import { INITIATE_CHANGE_EMAIL_SECONDARY } from "../UserSettings/GraphQL/mutations";
import { showAddSecondaryEmailModal } from "../UserSettings/userSettings.slice";
import { getLocaleFromIso2Code } from "../UserSettings/userSettings.utils";
import { ConfirmSecondaryEmailNotification } from "./ConfirmSecondaryEmailNotification";
import {
  UPDATE_USER_LINKEDIN_URL,
  UPDATE_USER_PHONE_NUMBER
} from "./UserAdditionaInfoOverlay.queries";
import { PhoneInputGlobalStyles, ServerError } from "./UserAdditionalInfoOverlay.styles";
import { isValidLinkedInUrl } from "./UserAdditionalInfoOverlay.utils";

type FormValues = {
  email: string;
  linkedInUrl: string;
  phoneNumber: string;
};

export const UserAdditionalInfoOverlay = () => {
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serverError, setServerError] = useState("");
  const [isEmailConfirmationModalOpen, setIsEmailConfirmationModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const client = useApolloClient();

  // NOTE: This refetch will happen after ALL parallel requests have been
  // completed since Apollo automatically dedupes requests, so it's safe to
  // refetch multiple times, and we'll always get the result of the last request made.
  const { refetch } = useQuery(GET_USER_BOOTSTRAP);
  const [initiateChangeEmail] = useMutation(INITIATE_CHANGE_EMAIL_SECONDARY);

  const onError = (error: ApolloError) => {
    dispatch(showAddSecondaryEmailModal(false));
    dispatch(setServerError(error.message));
  };
  const mutationHandlers = { onCompleted: refetch, onError };

  const [updateUserLinkedInUrl] = useMutation(UPDATE_USER_LINKEDIN_URL, mutationHandlers);

  const [updateUserPhoneNumber] = useMutation(UPDATE_USER_PHONE_NUMBER, mutationHandlers);

  const closeAndResetView = () => {
    dispatch(showAddSecondaryEmailModal(false));
  };

  async function setEmailChangeView(data: FormValues) {
    try {
      await initiateChangeEmail({ variables: { email: data.email } });
    } catch (error: any) {
      setServerError(error.message);
      return;
    }

    if (data.linkedInUrl) {
      updateUserLinkedInUrl({ variables: { linkedInUrl: data.linkedInUrl } });
    }

    if (phoneNumber) {
      // @ts-ignore
      const iso2Code = phoneInputRef?.current?.getInstance()?.selectedCountryData?.iso2;
      const locale = getLocaleFromIso2Code(iso2Code);

      updateUserPhoneNumber({ variables: { locale, phoneNumber } });
    }

    setIsEmailConfirmationModalOpen(true);
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty }
  } = useForm({
    mode: "all"
  });

  const phoneInputRef = useRef(null);
  const formHasErrors = Object.keys(errors).length > 0;

  const isDisabled = !isDirty || formHasErrors || (phoneNumber && !isPhoneNumberValid);

  const onSubmit = (data: FormValues) => {
    setEmailChangeView(data);
  };

  const handleLogout = async () => {
    dispatch({ type: "ACTION_AUTH_LOGOUT" });
    // Clear the Apollo cache
    await client.clearStore();
  };

  const email = watch("email");

  return (
    <>
      <ResponsiveDialogModal
        maxWidth={700}
        show={isEmailConfirmationModalOpen}
        onCancel={closeAndResetView}
        closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
        iconButtonRow={
          <>
            {/* Need to add placeholder to replace Basic Dialog header space */}
            <div style={{ height: "30px" }}></div>
          </>
        }
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
      >
        <ConfirmSecondaryEmailNotification secondaryEmail={email} />
      </ResponsiveDialogModal>
      <ResponsiveDialogModal
        show
        fullWidth
        maxWidth={700}
        closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
        shouldCloseOnEsc={false}
      >
        <Flex maxWidth="85ch" style={{ margin: "0 auto", paddingTop: "5rem" }}>
          <Stack>
            <Stack>
              <SuitedCopySubheadline noMargin>Before You Move On</SuitedCopySubheadline>
              <p>
                Provide additional contact information to give recruiters more ways to connect with
                you about current and future opportunities.
              </p>
            </Stack>
            <form>
              <Stack space="lg">
                <Box>
                  <Stack space="none">
                    <SuitedAltInputLabel htmlFor="email">
                      Secondary/Personal Email*
                    </SuitedAltInputLabel>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required.",
                        validate: (email: string) =>
                          validator.isEmail(email) || "Email address is invalid"
                      }}
                      render={({ field }) => (
                        <StyledTextInput id="email" {...field} error={errors?.email?.message} />
                      )}
                    />
                    <InputErrorMessage message={errors?.email?.message} />
                    <SuitedCopyCaption
                      noMargin
                      style={{ fontSize: "0.8rem", marginTop: "0.25rem" }}
                    >
                      Please note: this email cannot be used for login.
                    </SuitedCopyCaption>
                  </Stack>
                </Box>
                <Box>
                  <Stack space="none">
                    <SuitedAltInputLabel htmlFor="linkedin-url">
                      LinkedIn Profile Url
                    </SuitedAltInputLabel>
                    <Controller
                      name="linkedInUrl"
                      control={control}
                      rules={{
                        validate: (url: string) => {
                          if (!url) {
                            return true;
                          }

                          return isValidLinkedInUrl(url) || "LinkedIn URL is invalid";
                        }
                      }}
                      render={({ field }) => (
                        <StyledTextInput
                          id="linkedin-url"
                          {...field}
                          error={errors?.linkedInUrl?.message}
                          placeholder="https://www.linkedin.com/in/janedoe/"
                        />
                      )}
                    />
                    <InputErrorMessage message={errors?.linkedInUrl?.message} />
                  </Stack>
                </Box>
                <Box>
                  <Stack space="none">
                    <SuitedAltInputLabel htmlFor="phone">Phone Number</SuitedAltInputLabel>
                    <PhoneInputGlobalStyles />
                    <IntlTelInput
                      ref={phoneInputRef}
                      inputProps={{
                        className: "intl-tel-input-custom-styles"
                      }}
                      onChangeNumber={(value) => {
                        setPhoneNumber(value);
                      }}
                      onChangeValidity={(isValid) => {
                        setIsPhoneNumberValid(isValid);
                      }}
                      initOptions={{
                        initialCountry: "us"
                      }}
                    />
                    <InputErrorMessage
                      message={
                        phoneNumber && !isPhoneNumberValid
                          ? "Please enter a valid phone number"
                          : ""
                      }
                    />
                  </Stack>
                </Box>
              </Stack>
              <HStack style={{ marginTop: "2rem" }}>
                <Box>
                  <SuitedButton
                    purpose="primary"
                    type="submit"
                    disabled={!!isDisabled}
                    onClick={handleSubmit(onSubmit)}
                    delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                  >
                    Continue to Profile
                  </SuitedButton>
                </Box>
                <Box>
                  <SuitedButton purpose="default" onClick={handleLogout}>
                    Logout
                  </SuitedButton>
                </Box>
              </HStack>
              {serverError && (
                <ServerError>
                  {serverError} Please contact our{" "}
                  <SuitedLink
                    href="mailto:support@wellsuited.com"
                    target="_blank"
                    tooltip="Email Support"
                  >
                    support team
                  </SuitedLink>{" "}
                  for help.
                </ServerError>
              )}
            </form>
          </Stack>
        </Flex>
      </ResponsiveDialogModal>
    </>
  );
};
